@tailwind base;
@tailwind components;
@tailwind utilities;

.demo-text {
    position: fixed;
    background-color: red;
    top: -28px;
    right: -70px;
    font-size: 24px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    color: #f3f0f0; /* Ganti warna sesuai keinginan Anda */
    transform: rotate(45deg);
    z-index: 9999; /* Menempatkan teks di atas elemen lain */
}

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 50px;
}

.marquee {
    color: #f3f0f0;
    display: inline-block;
    width: 100%;
    animation: marquee 20s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}